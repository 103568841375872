import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function WorkGallery(props) {
  return (
    <section id="projectImages" className="section">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 project-img-left">
            {props.imagesLeft.map((item, index) => (
              <GatsbyImage key={`image-left-${index}`} image={getImage(item)} alt={item.title} />
            ))}
          </div>

          <div className="col-12 col-sm-12 col-md-6 project-img-right">
            {props.imagesRight.map((item, index) => (
              <GatsbyImage key={`image-right-${index}`} image={getImage(item)} alt={item.title} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkGallery
