import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Hero from '../components/Hero'
import Header from '../components/Header'
import WorkDescription from '../components/WorkDescription'
import WorkGallery from '../components/WorkGallery'
import WorkNext from '../components/WorkNext'
import Layout from '../components/Layout'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const SingleWork = ({ data, location }) => {
  const thisWork = get(data, 'contentfulPageWork')

  const allWorks = get(data, 'allContentfulPageWork').edges
  const thisEdge = allWorks.find((e) => e.node.slug === thisWork.slug)
  const prevWork = thisEdge?.previous?.slug
  const nextWork = thisEdge?.next?.slug

  return (
    <Layout>
      <Header />
      <Hero
        background={thisWork.backgroundImage}
        class="single-project-hero"
        tint="dark-bg"
        title={thisWork.client}
        sub={thisWork.categories.map((cat) => cat.title).join(', ')}
      />
      <WorkDescription>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
          <h2 className="big-title anim-bot">{thisWork.headline}</h2>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 anim-right">
          <div className="project-desc">{thisWork.body && renderRichText(thisWork.body)}</div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 desc-bottom desc-bottom-after-2 anim-bot"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 desc-bottom desc-bottom-after-1 anim-bot"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 desc-bottom desc-bottom-before-1 anim-right">
          <ul>
            {thisWork.services
              ?.filter((_, i) => i % 2 === 0)
              .map((elem, i) => (
                <li key={i}>{elem.title}</li>
              ))}
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 desc-bottom desc-bottom-before-2 anim-right">
          <ul>
            {thisWork.services
              ?.filter((_, i) => i % 2 === 1)
              .map((elem, i) => (
                <li key={i}>{elem.title}</li>
              ))}
          </ul>
        </div>
        {thisWork.url && (
          <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 work-link"></div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 work-link">
              <a class="work-btn" href={thisWork.url} target="_blank" rel="noreferrer">
                Visit site
              </a>
            </div>
          </>
        )}
      </WorkDescription>
      <WorkGallery imagesLeft={thisWork.images?.filter((_, i) => i % 2 === 0) ?? []} imagesRight={thisWork.images?.filter((_, i) => i % 2 === 1) ?? []} />
      <WorkNext prev={prevWork} next={nextWork} />
    </Layout>
  )
}

export default SingleWork

export const query = graphql`
  query SingleWorkQuery($slug: String) {
    contentfulPageWork(slug: { eq: $slug }) {
      categories {
        slug
        title
      }
      portfolioImage {
        gatsbyImageData(aspectRatio: 1.5, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
        title
      }
      id
      client
      slug
      url
      body {
        raw
      }
      backgroundImage {
        gatsbyImageData(aspectRatio: 1, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
        title
      }
      images {
        gatsbyImageData(aspectRatio: 1.5, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
        title
      }
      headline
      services {
        id
        title
      }
    }
    allContentfulPageWork(sort: { fields: date }) {
      edges {
        node {
          slug
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`
