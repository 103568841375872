import React from 'react'
import { PageLink } from '../elements/PageLink'

function WorkNext(props) {
  return (
    <section id="projectNext" className="section">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-6 project-nav">
            {props.prev && (
              <h1 className="big-title">
                <PageLink to={`/work/${props.prev}`}>Previous project</PageLink>
              </h1>
            )}
          </div>
          <div className="col-12 col-sm-6 project-nav">
            {props.next && (
              <h1 className="big-title">
                <PageLink to={`/work/${props.next}`}>Next project</PageLink>
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkNext
